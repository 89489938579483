<template>
  <!--Bad v-if expression add option to route to have navbar or not  -->
  <nav
    data-v-step="2"
    class="uk-margin uk-background-default uk-margin-remove-bottom"
    uk-navbar
    v-if="
      $route.name !== 'Home' &&
      $route.name !== 'Signup' &&
      $route.name != 'Mijn Activiteit' &&
      $route.name != 'Activity updaten' &&
      $route.name != 'Register' &&
      $route.name != 'LoginHome' &&
      $route.name != 'ErrorPage' &&
      $route.name != 'Invite' &&
      $route.name != 'TrainingenCatalogusWebflow' &&
      $route.name != 'Launch' &&
      $route.name != 'Evaluatie updaten' &&
      $route.name != 'RichTextView' &&
      $route.meta.isPublic != true &&
      has_navigation
    "
  >
    <div
      class="logo-head md:tw-hidden tw-flex uk-flex-between uk-flex-middle"
      :class="{ 'dftq-navbar-top-hidden': this.topNavBarHidden }"
    >
      <img :src="company_logo" alt="" />
    </div>

    <div
      class="max-navbar uk-navbar-left uk-width-4-5@l uk-margin-auto uk-flex uk-flex-between res-navbar uk-visible@m"
    >
      <div class="uk-flex uk-space-between">
        <router-link class="uk-navbar-item uk-logo" :to="url">
          <img id="logo" class="companylogo" :src="company_logo" />
        </router-link>
        <svg
          @click="this.opened = !this.opened"
          v-if="!opened"
          class="menu-burger"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
        <svg
          @click="this.opened = !this.opened"
          v-if="opened"
          class="menu-burger"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
      <div v-if="role == 'leerling' && opened">
        <ul class="uk-navbar-nav res-navbar">
          <li>
            <router-link class="dftq-router-link" to="/deelnemer/trainingen">
              {{ $translate("navbar.student.my_overview") }}</router-link
            >
          </li>
          <li>
            <router-link class="dftq-router-link" to="/deelnemer/resultaten">
              {{ $translate("navbar.student.my_results") }}</router-link
            >
          </li>
          <li class="mobile-only-option">
            <router-link class="dftq-router-link" to="/profiel">
              {{ $translate("navbar.student.my_profile") }}</router-link
            >
          </li>
          <li v-show="has_catalogue">
            <router-link class="dftq-router-link" to="/trainingen/catalogus">
              {{ $translate("navbar.student.catalogue") }}</router-link
            >
          </li>

          <li class="mobile-only-option uk-padding">
            <svg
              @click="logout"
              style="position: absolute; width: 28px; bottom: 6px; right: 20px"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              ></path>
            </svg>
          </li>
          <!-- 
          <li>
            <router-link to="/deelnemer/profiel">Profiel</router-link>
          </li> 
          -->
        </ul>
      </div>

      <div v-if="role == 'beheerder' && opened">
        <ul class="uk-navbar-nav res-navbar">
          <li>
            <router-link class="dftq-router-link" to="/dashboard">
              {{ $translate("navbar.supervisor.my_dashboard") }}</router-link
            >
          </li>
          <li>
            <router-link class="dftq-router-link" to="/trainingen">{{
              $translate("navbar.supervisor.my_courses")
            }}</router-link>
          </li>
          <li>
            <router-link class="dftq-router-link" to="/flows">{{
              $translate("navbar.supervisor.my_flows")
            }}</router-link>
          </li>
          <li>
            <router-link class="dftq-router-link" to="/deelnemers">{{
              $translate("navbar.supervisor.my_participants")
            }}</router-link>
          </li>
          <li>
            <router-link class="dftq-router-link" to="/administratie">{{
              $translate("navbar.supervisor.my_administration")
            }}</router-link>
          </li>
          <li>
            <router-link class="dftq-router-link" to="/trainingen/catalogus">
              {{ $translate("navbar.supervisor.catalogue") }}</router-link
            >
          </li>
          <li class="mobile-only-option">
            <router-link class="dftq-router-link" to="/profiel">{{
              $translate("navbar.supervisor.my_profile")
            }}</router-link>
          </li>

          <li class="mobile-only-option uk-padding">
            <svg
              @click="logout"
              style="position: absolute; width: 28px; bottom: 6px; right: 20px"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              ></path>
            </svg>
          </li>
        </ul>
      </div>

      <div v-if="(role == 'admin' || role == 'mini_admin') && opened">
        <ul class="uk-navbar-nav res-navbar">
          <li>
            <router-link class="dftq-router-link" to="/dashboard">{{
              $translate("navbar.admin.dashboard")
            }}</router-link>
          </li>

          <li>
            <router-link class="dftq-router-link" to="/trainingen">{{
              $translate("navbar.admin.courses")
            }}</router-link>
          </li>
          <li>
            <router-link class="dftq-router-link" to="/flows">{{
              $translate("navbar.admin.flows")
            }}</router-link>
          </li>
          <li>
            <router-link class="dftq-router-link" to="/deelnemers">{{
              $translate("navbar.admin.participants")
            }}</router-link>
          </li>

          <li>
            <router-link class="dftq-router-link" to="/trainingen/catalogus">{{
              $translate("navbar.admin.catalogue")
            }}</router-link>
          </li>

          <li class="mobile-only-option">
            <router-link class="dftq-router-link" to="/profiel">{{
              $translate("navbar.admin.profile")
            }}</router-link>
          </li>

          <li class="mobile-only-option uk-padding">
            <svg
              @click="logout"
              style="position: absolute; width: 28px; bottom: 6px; right: 20px"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              ></path>
            </svg>
          </li>
        </ul>
      </div>

      <button class="uk-text-muted" id="logout">
        {{ initials
        }}<svg
          class="profile-icon"
          id="Laag_1"
          data-name="Laag 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 11.85 6.22"
        >
          <path
            id="Path_212"
            data-name="Path 212"
            class="cls-1"
            d="M.75.75,5.92,5.47l3-2.75,2.16-2"
          />
        </svg>
      </button>
      <div
        class="profile-dropdown dftq-border uk-padding-small"
        uk-dropdown="mode: click"
        style="z-index: 120"
      >
        <ul class="uk-nav uk-dropdown-nav">
          <li>
            <router-link class="dropdown-link" to="/profiel"
              ><svg
                class="tw-w-6 tw-h-6 tw-mr-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              {{ $translate("navbar.student.profile") }}</router-link
            >
          </li>
          <li v-if="role == 'beheerder'" @click="startPlatformTour">
            <a class="dropdown-link"
              ><svg
                class="tw-w-6 tw-h-6 tw-mr-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                ></path></svg
              >{{ $translate("navbar.supervisor.helpcenter") }}</a
            >
          </li>
          <li v-if="role == 'admin' || role == 'mini_admin'">
            <router-link class="dropdown-link" to="/administratie"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="tw-w-6 tw-h-6 tw-mr-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>

              Administratie</router-link
            >
          </li>
          <li v-if="role == 'admin' || role == 'mini_admin'">
            <router-link class="dropdown-link" to="/admin/bedrijven"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="tw-w-6 tw-h-6 tw-mr-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                />
              </svg>

              {{ $translate("navbar.admin.companies") }}</router-link
            >
          </li>
          <li v-if="role == 'beheerder'">
            <router-link
              class="dropdown-link"
              to="/admin/bedrijven/profiel?id=0"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="tw-w-6 tw-h-6 tw-mr-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                />
              </svg>

              {{ $translate("company_page.my_company") }}</router-link
            >
          </li>
          <li v-if="role == 'admin' || role == 'mini_admin'">
            <router-link class="dropdown-link" to="/mails">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="tw-w-6 tw-h-6 tw-mr-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                />
              </svg>

              {{ $translate("navbar.admin.emails") }}</router-link
            >
          </li>

          <li>
            <a
              class="dropdown-link"
              href="https://deftiq.statuspage.io"
              target="_blank"
              ><svg
                class="tw-w-6 tw-h-6 tw-mr-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                ></path></svg
              >{{ $translate("navbar.system_status") }}</a
            >
          </li>
          <li
            v-show="
              role == 'admin' || role == 'mini_admin' || role == 'beheerder'
            "
          >
            <router-link class="dropdown-link" to="/tools">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style="width: 25px; margin-right: 10px"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                />
              </svg>
              Tools</router-link
            >
          </li>
          <li>
            <a class="dropdown-link" @click="logout"
              ><svg
                style="width: 25px; margin-left: 2px; margin-right: 8px"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                ></path></svg
              >{{ $translate("navbar.logout") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div
      class="dftq-navbar-bottom max-navbar uk-navbar-left res-navbar tw-flex md:tw-hidden"
      :class="{ 'dftq-navbar-bottom-hidden': this.bottomNavBarHidden }"
    >
      <div v-if="role == 'leerling'">
        <nav class="nav">
          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/deelnemer/trainingen"
          >
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 26">
              <defs></defs>
              <path
                class="b"
                d="M25.18,11.6L14.75,1.3,4.48,11.6c-.28,.28-.43,.66-.43,1.05v12.45H12.06v-5.89c0-.88,.72-1.6,1.6-1.6h2.34c.88,0,1.6,.72,1.6,1.6v5.89h8.02V12.66c0-.4-.16-.78-.44-1.06Z"
              />
            </svg>
          </router-link>
          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/deelnemer/resultaten"
          >
            <svg
              id="uuid-8f45cc9c-fe85-46b3-807c-d0a68264d93d"
              data-name="Laag 1"
              style="
                fill: #fff;
                transform: scale(1.6);
                stroke: #b4b4b4;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 0.75px;
              "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
            >
              <defs></defs>
              <rect
                class="uuid-8a076b81-c74a-4699-b530-b8c84814f1a5"
                x="9"
                y="17.8"
                width="2.4"
                height="3.2"
              />
              <rect
                class="uuid-8a076b81-c74a-4699-b530-b8c84814f1a5"
                x="13.8"
                y="14.6"
                width="2.4"
                height="6.4"
              />
              <rect
                class="uuid-8a076b81-c74a-4699-b530-b8c84814f1a5"
                x="18.6"
                y="9"
                width="2.4"
                height="12"
              />
            </svg>
          </router-link>
          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/trainingen/catalogus"
            v-show="has_catalogue"
          >
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 26">
              <defs></defs>
              <rect class="b" x="4.58" y="3.58" width="8.7" height="8.7" />
              <rect class="b" x="15.08" y="3.58" width="8.7" height="8.7" />
              <rect class="b" x="4.58" y="14.21" width="8.7" height="8.7" />
              <rect class="b" x="15.08" y="14.21" width="8.7" height="8.7" />
            </svg>
          </router-link>
          <a
            class="dftq-router-link bottom-nav-icon"
            @click="mobilePopupVisible = !mobilePopupVisible"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </a>
        </nav>
      </div>
      <div v-if="role == 'beheerder'">
        <nav class="nav">
          <router-link class="dftq-router-link bottom-nav-icon" to="/dashboard">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 29 26"
              stroke="currentColor"
            >
              <defs></defs>
              <path
                class=""
                d="M25.18,11.6L14.75,1.3,4.48,11.6c-.28,.28-.43,.66-.43,1.05v12.45H12.06v-5.89c0-.88,.72-1.6,1.6-1.6h2.34c.88,0,1.6,.72,1.6,1.6v5.89h8.02V12.66c0-.4-.16-.78-.44-1.06Z"
              />
            </svg>
          </router-link>
          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/trainingen"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 29 26"
              stroke="currentColor"
            >
              <defs></defs>
              <line class="" x1="2.61" y1="11.07" x2="2.61" y2="15.7" />
              <path
                class=""
                d="M6.48,10.39v9.69c0,1.52,3.59,2.75,8.02,2.75s8.02-1.23,8.02-2.75V10.39H6.48Z"
              />
              <polygon
                class=""
                points="28.18 10.66 14.5 16.32 .82 10.66 14.5 4.99 28.18 10.66"
              />
              <circle class="" cx="2.61" cy="16.61" r=".99" />
            </svg>
          </router-link>
          <router-link class="dftq-router-link bottom-nav-icon" to="/flows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          </router-link>

          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/deelnemers"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 29 26"
              fill="none"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <defs></defs>
              <ellipse class="" cx="14.3" cy="9.56" rx="4.96" ry="4.86" />
              <path
                class=""
                d="M6.17,23.15c0-3.97,3.3-7.2,7.36-7.2h1.54c3.96,0,7.36,3.33,7.36,7.2H6.17Z"
              />
            </svg>
          </router-link>
          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/administratie"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="tw-w-4 tw-h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>
          </router-link>
          <a
            class="dftq-router-link bottom-nav-icon"
            @click="mobilePopupVisible = !mobilePopupVisible"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </a>
        </nav>
      </div>
      <div v-if="role == 'admin'">
        <nav class="nav">
          <router-link class="dftq-router-link bottom-nav-icon" to="/dashboard">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 26">
              <defs></defs>
              <path
                class="b"
                d="M25.18,11.6L14.75,1.3,4.48,11.6c-.28,.28-.43,.66-.43,1.05v12.45H12.06v-5.89c0-.88,.72-1.6,1.6-1.6h2.34c.88,0,1.6,.72,1.6,1.6v5.89h8.02V12.66c0-.4-.16-.78-.44-1.06Z"
              />
            </svg>
          </router-link>
          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/admin/bedrijven"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 26">
              <defs></defs>
              <rect
                class="b"
                x="4.27"
                y="6.82"
                width="20.56"
                height="16.01"
                rx=".75"
                ry=".75"
              />
              <rect
                class="b"
                x="4.27"
                y="6.82"
                width="20.56"
                height="8.87"
                rx=".75"
                ry=".75"
              />
              <rect
                class="b"
                x="12.56"
                y="14.43"
                width="3.89"
                height="2.74"
                rx="1.14"
                ry="1.14"
              />
              <path
                class="c"
                d="M11.05,6.82c.42-1.56,1.83-2.66,3.44-2.68,1.63-.02,3.09,1.09,3.51,2.68"
              />
            </svg>
          </router-link>
          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/trainingen"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 26">
              <defs></defs>
              <line class="c" x1="2.61" y1="11.07" x2="2.61" y2="15.7" />
              <path
                class="c"
                d="M6.48,10.39v9.69c0,1.52,3.59,2.75,8.02,2.75s8.02-1.23,8.02-2.75V10.39H6.48Z"
              />
              <polygon
                class="c"
                points="28.18 10.66 14.5 16.32 .82 10.66 14.5 4.99 28.18 10.66"
              />
              <circle class="b" cx="2.61" cy="16.61" r=".99" />
            </svg>
          </router-link>
          <router-link
            class="dftq-router-link bottom-nav-icon"
            to="/deelnemers"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 26">
              <defs></defs>
              <ellipse class="b" cx="14.3" cy="9.56" rx="4.96" ry="4.86" />
              <path
                class="c"
                d="M6.17,23.15c0-3.97,3.3-7.2,7.36-7.2h1.54c3.96,0,7.36,3.33,7.36,7.2H6.17Z"
              />
            </svg>
          </router-link>
          <a
            class="dftq-router-link bottom-nav-icon"
            @click="mobilePopupVisible = !mobilePopupVisible"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </a>
        </nav>
      </div>
    </div>
    <mobile-popup v-if="mobilePopupVisible">
      <h5
        class="dftq-font-medium tw-cursor-pointer"
        @click="
          () => {
            this.$router.push('/profiel');
            this.mobilePopupVisible = false;
          }
        "
      >
        {{ $translate("navbar.profile_admin") }}
      </h5>
      <h5
        class="dftq-font-medium tw-cursor-pointer uk-margin-remove-top"
        @click="
          () => {
            this.$router.push('/trainingen/catalogus');
            this.mobilePopupVisible = false;
          }
        "
        v-if="role != 'leerling'"
      >
        {{ $translate("navbar.admin.catalogue") }}
      </h5>
      <h5
        class="dftq-font-medium tw-cursor-pointer uk-margin-remove-top"
        @click="openStatusPage"
      >
        {{ $translate("navbar.system_status") }}
      </h5>
      <h5
        class="dftq-font-medium tw-cursor-pointer uk-margin-remove-top"
        @click="
          () => {
            this.logout();
            this.mobilePopupVisible = false;
          }
        "
      >
        {{ $translate("navbar.logout") }}
      </h5>
      <h5
        class="dftq-font-medium uk-margin-remove tw-cursor-pointer"
        @click="mobilePopupVisible = !mobilePopupVisible"
      >
        {{ $translate("cancel") }}
      </h5>
    </mobile-popup>
  </nav>
</template>

<script>
import Config from "../config/config.js";
import {
  updateLogo,
  checkValidBrowser,
  prepareLogin,
  getQueryParams,
} from "../config/deftiq.js";
import { getCookie, prepareLogout } from "../config/deftiq.js";
import { useToast } from "vue-toastification";

import MobilePopup from "./inputs/MobilePopup.vue";

const supported_languages = {
  nl: true,
  en: true,
  fr: true,
  es: true,
  de: true,
};

export default {
  name: "NavBar",
  data() {
    return {
      role: "",
      email: "",
      initials: "",
      url: "",
      has_catalogue: true,
      opened: window.innerWidth > 900,
      company_logo: "",
      emailUrl: process.env.VUE_APP_EMAILS,
      mobilePopupVisible: false,
      has_navigation: true,
    };
  },
  beforeMount() {
    // this.startPlatformTour();
  },
  components: {
    MobilePopup,
  },
  computed: {
    topNavBarHidden: function () {
      return this.$store.state.navBarVisibility.topHidden;
    },
    bottomNavBarHidden: function () {
      return this.$store.state.navBarVisibility.bottomHidden;
    },
  },
  watch: {
    "$store.state.company_logo": function () {
      if (this.$store.state.company_logo != this.company_logo) {
        this.updateCompanyLogo(this.$store.state.company_logo);
      }
    },
    "$store.state.logged_in": function (value) {
      /*
      Load the data in the navbar once the user is logged in 
      */
      if (value) {
        this.loadUserData();
        this.logoutWhenCookieExpires();
      }
    },
  },
  methods: {
    logout: function () {
      fetch(`${Config.baseURL}/public/auth/logout/`, {
        method: "GET",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "good") {
            prepareLogout();
            this.$store.commit("setLoggedin", false);
            this.$router.push("/");
          }
        });
    },
    updateCompanyLogo: function (logo) {
      this.company_logo = Config.baseURL + "/images/" + logo;
    },
    startPlatformTour: function () {
      this.$store.commit("startBeheerderTour", true);
    },
    openStatusPage: function () {
      window.location.replace("https://deftiq.statuspage.io");
    },
    loadUserData: function () {
      fetch(`${Config.baseURL}/user/identify`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          this.has_catalogue = data.has_catalogue_access == 1;
          data.has_catalogue_access = this.has_catalogue;
          this.role = data.user_role;
          this.email = data.email;
          this.initials =
            data.firstname.substring(0, 1) + data.lastname.substring(0, 1);
          this.initials = this.initials.toUpperCase();

          this.$store.commit("setUser", data);

          //sessionstorage will be phased out in favor of Vuex
          for (let [key, value] of Object.entries(data)) {
            sessionStorage.setItem(key, value);
          }
          updateLogo(data.company_logo);
          this.updateCompanyLogo(data.company_logo);

          let language =
            supported_languages[data.lang] == undefined ? "en" : data.lang;

          /*
          if (sessionStorage.getItem("user_role") != "leerling") {
            // eslint-disable-next-line no-undef
            Weglot.switchTo(language);
          }
          */

          localStorage.setItem("dftq-lang", language);
          this.$store.commit("updateLanguage", language);

          //disable tracking for admin users
          if (process.env.VUE_APP_TESTMODE === "NO") {
            this.$gtm.enable(data.user_role != "admin");
            if (data.user_role != "admin") {
              // eslint-disable-next-line no-undef
              fbq("track", "PageView");
              if (data.user_role == "beheerder") {
                // eslint-disable-next-line no-undef
                fbq("trackCustom", "APP - Beheerders");
              }
            }
          } else {
            this.$gtm.enable(false);
          }

          //check if the browser is valid
          checkValidBrowser(this.$route.name);

          this.url =
            this.role == "leerling" ? "/deelnemer/trainingen" : "/dashboard";
        });

      //perform browser logging
      fetch(`${Config.baseURL}/user/log`, {
        credentials: "include",
      });
    },
    logoutWhenCookieExpires: function () {
      let expiresAt = sessionStorage.getItem("session_expires_at");
      //we cannot determine when the user session started so terminate
      if (isNaN(expiresAt) || expiresAt == null) {
        return;
      }
      let currentTime = Date.now() / 1000;
      let diff = Math.round(expiresAt - currentTime);
      setTimeout(() => {
        const toast = useToast();
        //compute time of cookie
        toast(this.$translate("navbar.session_expires.in_one_hour"));
      }, (diff - 10 * 60) * 1000);
      setTimeout(() => {
        const toast = useToast();
        //compute time of cookie
        toast(this.$translate("navbar.session_expires.expired"));
        this.logout();
      }, diff * 1000);
    },
  },

  created() {
    if (getCookie("logged_in") == "1") {
      this.loadUserData();
      this.logoutWhenCookieExpires();
    }

    const params = getQueryParams();

    //Used as part of the bouwpas integration to allow for automated login.
    if (params.get("deelnemerlogin") == 1) {
      prepareLogin(null, "leerling");
      this.$store.commit("setLoggedin", true);
      this.$store.dispatch("fetchCsrfToken");
    }

    if (params.get("hidenavigation") == "1") {
      this.has_navigation = false;
      sessionStorage.setItem("has_navigation", this.has_navigation);
    }

    if (sessionStorage.getItem("has_navigation") != null) {
      this.has_navigation = sessionStorage.getItem("has_navigation") === "true";
    }

    try {
      //listen to the broadcast channel to see if other windows and tabs are opened.
      const channel = new BroadcastChannel("deftiq");
      channel.onmessage = (e) => {
        if (getCookie("logged_in") == "1") {
          if (
            e.data.event == "logged_in" &&
            e.data.id != sessionStorage.getItem("window_identifier")
          ) {
            this.loadUserData();
            //check if someone logs in as a different role or uses the same role
            if (this.$route.path == getCookie("redirect")) {
              this.$router.go(); //refresh the current route
            } else {
              this.$router.replace(getCookie("redirect"));
            }
          } else if (e.data.event == "logged_out") {
            this.logout();
          }
        }
      };
    } catch (error) {
      console.log(error);
    }
  },
  mounted: function () {
    // channel.close(); //not closed so no garbage collection. But needs to remain open.
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
}

.max-navbar {
  max-width: 1420px;
}

#logo {
  height: 30px;
  max-height: 30px;
  padding-left: 14px;
}

.profile-icon {
  width: 12px;
  stroke: #b4b4b4;
  margin-top: -2px;
  margin-left: 8px;
}

.profile-dropdown {
  border-radius: 3px;
  box-shadow: 0px 0px 3px #00000029 !important;
}

.dropdown-link:hover {
  color: #6db2ef;
}

.uk-navbar-nav {
  font-size: 12px;
}

.uk-navbar-nav > li > a {
  text-transform: capitalize;
}

.mobile-only-option {
  display: none;
}

#logout {
  border: 1px solid #b4b4b4;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 0px;
  font-size: 11px;

  border-radius: 6px;
  /*height: 30px;  */
  background-color: white;
}

#logout:hover {
  border-color: #6db2ef;
  color: #6db2ef !important;
}

#logout:hover .profile-icon {
  border-color: #6db2ef;
  stroke: #6db2ef !important;
  color: #6db2ef !important;
}

#logout[aria-expanded="true"] .profile-icon {
  transform: rotate(180deg);
}

.dftq-router-link {
  font-weight: 500;
  font-size: 12px !important;
  padding-left: 30px;
  padding-right: 30px;
}

.dftq-router-link:hover {
  color: #88c0f1;
}

.router-link-active {
  font-weight: 500;

  color: #193155;
}

.router-link-active > span {
  font-weight: 500;

  color: #193155;
}

.router-link-active > svg {
  stroke: #193155;
}

.res-navbar {
  flex-direction: row;
}

.menu-burger {
  display: none;
}

svg {
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
  stroke: #b4b4b4;
  stroke-width: 1;
  fill: white;
}

@media only screen and (max-width: 1140px) {
  .dftq-router-link {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  #logout {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 1000px) {
  .res-navbar {
    width: 100vw;
    z-index: 2;
    bottom: 0;
    position: fixed;
  }

  .dftq-router-link {
    padding: 10px 5px 5px 5px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-only-option {
    display: block;
  }

  .menu-burger {
    display: block;
    width: 36px;
    position: absolute;
    top: 20px;
    right: 18px;
  }

  .companylogo {
    position: absolute;
    top: 24px;
    left: 0px;
  }

  .dftq-router-link {
    min-height: 60px;
  }

  #logout {
    display: none;
  }

  /* mehmed aanpassingen */

  .nav {
    position: fixed;

    bottom: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
  }

  .nav-text {
    font-size: 9px;
    color: #b4b4b4;
  }

  .bottom-nav-icon {
    width: 24px;
    min-height: 0px !important;
    padding-top: 12px !important;
    padding-left: 8px;
    padding-right: 8px;
  }

  .router-link-active {
    stroke: current;
  }

  .router-link-active > span {
    stroke: current;
  }

  .router-link-active > svg {
    stroke: current;
  }

  .router-link-exact-active {
    color: #193155;
    border-bottom: 2px solid #193155;
  }
}

@media only screen and (max-width: 600px) {
  .dftq-navbar-top-hidden,
  .dftq-navbar-bottom-hidden {
    display: none;
  }
}

.logo-head {
  padding: 15px 15px 15px 7px;
  margin-left: 10px !important;
  width: 100%;
  max-height: 50px;
}

.logo-head > img {
  width: 70px;
}
</style>
