<template>
  <div id="feedbackContainer" v-if="opened">
    <svg
      @click="opened = !opened"
      id="close-icon"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
    <div class="uk-padding" v-if="!asked">
      <h4 class="uk-margin-remove-top dftq-font-medium uk-margin-remove-bottom">
        {{ $translate("feedback_button.question") }}
      </h4>
      <p class="tw-m-0 tw-text-xs" v-if="!supportIsOpen">
        {{ $translate("feedback_button.support_opened") }}
      </p>
      <div class="uk-margin-medium dftq-form-group" v-if="!is_logged_in">
        <input
          class="uk-input dftq-input"
          id="firstname"
          v-model="email"
          :class="{ 'dftq-input-wrong': !correct_inputs.email }"
          type="text"
          placeholder=" "
        />
        <label class="dftq-placeholder" for="firstname">{{
          $translate("email")
        }}</label>
        <label v-show="!correct_inputs.email" class="error-text">{{
          $translate("feedback_button.invalid_email")
        }}</label>
      </div>
      <div class="uk-margin-medium dftq-form-group">
        <input
          class="uk-input dftq-input"
          id="firstname"
          v-model="subject"
          :class="{ 'dftq-input-wrong': !correct_inputs.subject }"
          type="text"
          placeholder=" "
        />
        <label class="dftq-placeholder" for="firstname">{{
          $translate("feedback_button.subject")
        }}</label>
        <label v-show="!correct_inputs.subject" class="error-text">{{
          $translate("feedback_button.invalid_subject")
        }}</label>
      </div>
      <div class="uk-margin-medium dftq-form-group">
        <textarea
          class="uk-textarea dftq-input"
          style="resize: none"
          v-model="message"
          :class="{ 'dftq-input-wrong': !correct_inputs.message }"
          rows="5"
          id="good_feedback"
          type="text"
          placeholder=" "
        ></textarea>
        <label class="dftq-placeholder" for="good_feedback">{{
          $translate("feedback_button.message")
        }}</label>
        <label v-show="!correct_inputs.message" class="error-text-message">{{
          $translate("feedback_button.invalid_message")
        }}</label>
      </div>

      <button
        @click="sendQuestion"
        class="uk-button uk-button-primary dftq-button uk-margin-medium-top uk-margin-medium-bottom uk-float-right"
        style="margin-right: 0px !important"
      >
        {{ $translate("feedback_button.send") }}
      </button>
    </div>
    <div v-else class="uk-padding">
      <h4 class="uk-margin-remove-top dftq-font-medium uk-margin-remove-bottom">
        {{ $translate("feedback_button.question_thanks") }}
      </h4>
      <p class="uk-margin-medium">
        {{ $translate("feedback_button.received_message_greetings.first") }}
        <br /><br />
        {{ $translate("feedback_button.received_message_greetings.second")
        }}<br v-if="has_deftiq_branding" />
        <span v-if="has_deftiq_branding"
          >{{
            $translate("feedback_button.received_message_greetings.third")
          }} </span
        ><br />
        <span v-if="has_deftiq_branding">
          {{
            $translate("feedback_button.received_message_greetings.fourth")
          }}</span
        >
        {{ has_deftiq_branding ? "Deftiq" : company_name }}
      </p>
    </div>
  </div>

  <div
    class="bookingsflow-mobile-button"
    @click="launchBookingPopup"
    v-if="is_logged_in && !isDeelnemer && $route.meta.isPublic != true"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="white"
      class=""
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  </div>

  <button @click="opened = !opened" id="feedbackButton">
    <img
      src="@/assets/icon_questions.svg"
      alt=""
      class="tw-w-full tw-h-full tw-rounded-full tw-cursor-pointer hover:tw-brightness-95"
    />
  </button>

  <!--
  <button
    @click="opened = !opened"
    class="mobileFeedbackButton uk-hidden@m dftq-font-bold"
  >
    {{ $translate("feedback_button.ask_question") }}
  </button>
  -->
</template>

<script>
import Config from "../config/config.js";
import { getCookie } from "../config/deftiq.js";

export default {
  name: "FeedbackButton",
  data: function () {
    return {
      opened: false,
      subject: "",
      message: "",
      email: "",
      is_logged_in: getCookie("logged_in") == "1",
      isDeelnemer: sessionStorage.getItem("user_role") == "leerling",
      company_name: sessionStorage.getItem("company_name"),
      has_deftiq_branding: sessionStorage.getItem("has_deftiq_branding") == 1,
      correct_inputs: {
        subject: true,
        email: true,
        message: true,
      },
      asked: false,
    };
  },
  mounted() {},
  watch: {
    //listen for the change to determine fields in feedback button
    "$store.state.logged_in": function () {
      this.is_logged_in = getCookie("logged_in") == "1";
    },
  },

  computed: {
    supportIsOpen: function () {
      let date = new Date();
      let hour = parseInt(
        date
          .toLocaleString("nl-NL", {
            hour: "numeric",
            hour12: false,
            timeZone: "Europe/Amsterdam",
          })
          .replace(/^0/, "")
      );

      return hour >= 9 && hour < 17;
    },
  },
  methods: {
    launchBookingPopup: function () {
      this.$router.push("/intern/booking");
    },
    validateInputs: function () {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.correct_inputs.email = re.test(this.email) || this.is_logged_in;
      this.correct_inputs.subject = this.subject.length > 0;
      this.correct_inputs.message = this.message.length > 0;
      return Object.values(this.correct_inputs).every((e) => e === true);
    },
    sendQuestion: function () {
      if (!this.validateInputs()) {
        return;
      }

      let params = new FormData();
      params.append("subject", this.subject);
      params.append("message", this.message);
      //do not send the url if there is a token present
      if (
        !this.$route.path.includes("register") &&
        !this.$route.path.includes("invite")
      ) {
        params.append("url", window.location.href);
      } else {
        params.append("url", "Direct Access pagina");
      }

      let endpoint = "/create";
      if (!this.is_logged_in) {
        params.append("email", this.email);
        endpoint = "/public/create";
      } else if (sessionStorage.getItem("enrolled_by_company") != null) {
        params.append(
          "company_id",
          sessionStorage.getItem("enrolled_by_company")
        );
        params.append(
          "course_shortname",
          sessionStorage.getItem("current_course_shortname")
        );
      }

      fetch(`${Config.baseURL}/public/feedback${endpoint}`, {
        method: "POST",
        body: params,
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          this.asked = data.status == "good";
        });
    },
  },
};
</script>

<style scoped>
#feedbackContainer {
  max-width: 350px;
  width: 90%;
  min-height: 390px;
  background-color: white;
  position: absolute;
  border-radius: 8px;
  bottom: 90px;
  right: 30px;
  z-index: 30;
  box-shadow: 0px 0px 13px #00000012 !important;
}

#feedbackButton {
  position: absolute;
  right: 15px;
  bottom: 5px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: none;
  background-color: transparent;
  z-index: 2;
}

#close-icon {
  width: 30px;
  position: absolute;
  right: 4px;
  top: 4px;
  color: #b7b7b7;
}

#close-icon:hover {
  color: #6db2ef;
}

.bookingsflow-mobile-button {
  display: none;
}

@media only screen and (max-width: 600px) {
  .bookingsflow-mobile-button {
    display: none;
  }

  .bookingsflow-mobile-button > svg {
    width: 32px;
    text-align: center;
  }
}

@media only screen and (max-width: 1000px) {
  /*
  .mobileFeedbackButton {
    position: absolute;
    right: -44px;
    bottom: 50%;
    transform: rotate(-90deg);
    width: 120px;
    height: 32px;
    border-radius: 4px 4px 0px 0px;
    border: none;
    background-color: #88c0f1;
    color: white;
    font-weight: 500;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    z-index: 2;
  }
 */
  #feedbackButton {
    bottom: 50px;
    right: 0px;
    width: 72px;
    height: 72px;
  }

  #feedbackContainer {
    max-width: 100%;
    width: 100%;
    min-height: 390px;
    background-color: white;
    position: fixed;
    border-radius: 8px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    z-index: 30;
    box-shadow: 0px 0px 13px #00000012 !important;
  }
}
</style>
