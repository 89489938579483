import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createGtm } from "@gtm-support/vue-gtm";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import translations from "./core/translations.js";
import Toast, { POSITION } from "vue-toastification";

import i18nPlugin from "./plugins/i18n";

import StandardInput from "@/components/inputs/StandardInput.vue";

import "vue-toastification/dist/index.css";
// Import the CSS or use your own!
import "./assets/css/uikit.deftiq.min.css";
import "./assets/css/app.css";
import "./assets/css/tailwind.css";
import store from "./config/store.js";

//Toastification options - small update
const options = {
  // You can set your default options here
  position: POSITION.BOTTOM_RIGHT,
  transition: "Vue-Toastification__fade",
  timeout: 5000,
  hideProgressBar: true,
};

const app = createApp(App);

if (process.env.VUE_APP_TESTMODE === "NO") {
  Sentry.init({
    app,
    dsn: "https://8e1a82db800c4c3d9d390b2a866a8187@o1085695.ingest.sentry.io/6097232",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "app.deftiq.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    logErrors: true,
  });
}

//initialize the store
app.use(store);

app.use(i18nPlugin, translations);

app.use(Toast, options);
app.use(
  createGtm({
    id: "GTM-W6RDNCV", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    /*
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x'
    },
    */
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: "2726c7f26c", // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  })
);

app.use(router).mount("#app");

app.component("StandardInput", StandardInput);
